/** *************************************************************
* Copyright (C) 2016-2024 DeepSurface Security, Inc.  All rights reserved. *
***************************************************************/

import React from 'react';
import InlineSVG from '../shared/InlineSVG';
import Loading from '../shared/Loading';
import { Remarkable } from 'remarkable';
import { v4 as uuidv4 } from 'uuid';
import {
  decodeURLHash,
  isNotEmpty,
  serializeURLHash,
} from '../shared/Utilities';
import {
  fetchHelpDocumentation,
  getChangeLogContent,
  getLicensesContent,
  getMainSectionPageKeys,
  productInformationPages,
  pageOrderAndLabels,
  getLabelFor,
  getOrderedPagesForSection,
  mainOrderAndLabels,
  getOrderedSectionsForMain,
} from './Shared';

const ProductInformationPageContent = ( { content, pageKey, md } ) => {

  const [ allContent, setAllContent ] = React.useState( null );

  React.useEffect( () => {
    if ( isNotEmpty( content ) && isNotEmpty( pageKey ) ) {
      if ( pageKey === 'changelogs' || pageKey === 'licenses' ) {
        const _content = [];

        Object.values( content ).map( c => _content.push( c.content ) );
        setAllContent( _content.reverse() );
      } else if ( pageKey === 'eula' ) {
        setAllContent( [ `<pre>${content.content}</pre>` ] );
      }
    }
  }, [ content, pageKey ] );

  return (
    <React.Fragment>
      {
        isNotEmpty( allContent ) &&
        allContent.map( ( content, i ) => {
          return <div
            key={i}
            className={ `markdownContent pageContent ${pageKey}`}
            dangerouslySetInnerHTML={
              { __html: md.render( content ) }
            }
          />;
        } )
      }
    </React.Fragment>
  );
};

const Content = ( ) => {
  /* eslint-disable camelcase */
  const [ helpContent, setHelpContent ] = React.useState( null );
  const [ mainKey, setMainKey ] = React.useState( 'installation_guide' );
  const [ sectionKey, setSectionKey ] = React.useState( 'reporting' );
  const [ pageKey, setPageKey ] = React.useState( 'documentation_index' );
  const [ previousPage, setPreviousPage ] = React.useState( null );
  const [ nextPage, setNextPage ] = React.useState( null );

  const helpContentContainer = React.useRef( null );

  const [ loading, setLoading ] = React.useState( false );

  const md = new Remarkable( {
    html: true,
  } );

  const setupHelpContent = async () => {
    setLoading( true );
    let _help_page, _previousPage, _nextPage, _content, _mainKey, _sectionKey, _pageKey;
    const search = Object.fromEntries( new URLSearchParams( location.search ) );
    const hash = decodeURLHash();
    const params = { ...search, ...hash }; // Currently, hash wins
    if ( document.location.href.includes( '#' ) ) {
      // Hack to redirect user if they clicked on a # based link in a page body
      window.location = window.location.pathname + '?' + serializeURLHash( params );
    }

    if ( isNotEmpty( params.help_page ) ) {
      _help_page = params.help_page;
    } else {
      _help_page = 'documentation_index';
      location.search = '?' + serializeURLHash( { help_page: _help_page } );
    }

    // need to do something completely different for the dynamically generated and built product info pages
    if ( productInformationPages.includes( _help_page ) ) {
      _mainKey = 'product_information';
      _sectionKey = null;
      _pageKey = _help_page;
      if ( _help_page === 'changelogs' ) {
        _content = await getChangeLogContent();
      }
      if ( _help_page === 'licenses' ) {
        _content = await getLicensesContent();
      }
      if ( _help_page === 'eula' ) {
        _content = await fetchHelpDocumentation( _help_page );
      }
    // regular page
    } else {
      _content = await fetchHelpDocumentation( _help_page );
      const _keys = getMainSectionPageKeys( 'page', _help_page );

      ( { _mainKey, _sectionKey, _pageKey } = _keys );
    }
    const pageIndex = pageOrderAndLabels.findIndex( p => p.pageKey === _help_page );

    const pageLabel = getLabelFor( _pageKey, 'page' );
    const sectionLabel = getLabelFor( _sectionKey, 'section' );
    const mainLabel = getLabelFor( _mainKey, 'main' );

    if ( pageIndex > 0 ) {
      _previousPage = pageOrderAndLabels[pageIndex - 1];
    }
    if ( pageIndex < pageOrderAndLabels.length ) {
      _nextPage = pageOrderAndLabels[pageIndex + 1];
    }

    let title = 'DeepSurface Documentation - ';
    let description = 'DeepSurface Documentation: ';
    let keywords = 'documentation,';

    if ( isNotEmpty( mainLabel ) && mainLabel !== 'N/A' ) {
      title += `${mainLabel} | `;
      description += `${mainLabel}, `;
      keywords += `${mainLabel},`;
    }
    if ( isNotEmpty( sectionLabel ) && sectionLabel !== 'N/A' ) {
      title += `${sectionLabel} | `;
      description += `${sectionLabel}, `;
      keywords += `${sectionLabel},`;
    }
    if ( isNotEmpty( pageLabel ) && pageLabel !== 'N/A' ) {
      title += pageLabel;
      description += `${pageLabel}`;
      keywords += `${pageLabel}`;
    }

    // set meta tags and information
    document.title = title;
    const descriptionMeta = document.querySelector( 'meta[name=\'description\']' );
    if ( isNotEmpty( descriptionMeta ) ) {
      descriptionMeta.setAttribute( 'content', description );
    }
    const keywordsMeta = document.querySelector( 'meta[name=\'keywords\']' );
    if ( isNotEmpty( keywordsMeta ) ) {
      keywordsMeta.setAttribute( 'content', keywords );
    }

    const canonicalMeta = document.querySelector( 'link[rel=\'canonical\']' );
    if ( isNotEmpty( canonicalMeta ) ) {
      canonicalMeta.setAttribute( 'href', window.location.href );
    }

    const titleOG = document.querySelector( 'meta[property=\'og:title\']' );
    if ( isNotEmpty( titleOG ) ) {
      titleOG.setAttribute( 'content', title );
    }
    const descriptionOG = document.querySelector( 'meta[property=\'og:description\']' );
    if ( isNotEmpty( descriptionOG ) ) {
      descriptionOG.setAttribute( 'content', description );
    }

    setMainKey( _mainKey );
    setSectionKey( _sectionKey );
    setPageKey( _pageKey );
    setPreviousPage( _previousPage );
    setNextPage( _nextPage );
    setHelpContent( _content );
    setLoading( false );
  };

  const handleHashChange = () => {
    setupHelpContent();
  };

  React.useEffect( ( ) => {
    setupHelpContent();
    const search = Object.fromEntries( new URLSearchParams( location.search ) );
    const hash = decodeURLHash();
    const params = { ...search, ...hash }; // Currently, hash wins

    if ( isNotEmpty( params.help_page ) ) {
      const page = pageOrderAndLabels.find( p => p.pageKey === params.help_page );
      const _mainKey = page?.mainKey;
      const _sectionKey = page?.sectionKey;
      const _pageKey = params.help_page;

      setMainKey( _mainKey );
      setSectionKey( _sectionKey );
      setPageKey( _pageKey );
    }

    window.addEventListener( 'hashchange', handleHashChange );
    return () => {
      window.removeEventListener( 'hashchange', handleHashChange );
    };
  }, [ helpContentContainer ] );

  return (
    <div className="helpDocumentationContainer">
      <div className="helpMenu" id="helpMenu" >
        <ul>
          {
            mainOrderAndLabels.map( _main => {
              if ( _main.mainKey !== 'product_information' ) {
                return <li
                  // eslint-disable-next-line max-len
                  className={ `menuMain ${_main.mainKey} collapsibleSectionWrapper ${ mainKey !== _main.mainKey ? 'collapsed' : ''}` }
                  key={ `${_main.mainKey}_${uuidv4()}`}
                >
                  <a
                    className="menuMainLabel collapsibleSectionHeader"
                    href={ `${window.location.pathname}?help_page=${_main.mainKey}_main_index` }
                  >
                    <div className="headerLeft">
                      <h3>{ _main.label }</h3>
                    </div>
                    <div className="headerRight">
                      <strong className="sectionCount">
                        { getOrderedSectionsForMain( _main.mainKey )?.length }
                      </strong>
                      <span className="carretWrapper">
                        <InlineSVG type="carretUp"/>
                      </span>
                    </div>
                  </a>
                  <div className="collapsibleSectionBody">
                    <ul>
                      {
                        getOrderedSectionsForMain( _main.mainKey )?.map( _section => {
                          return <li
                            // eslint-disable-next-line max-len
                            className={ `menuSection ${_section.sectionKey} collapsibleSectionWrapper ${ sectionKey !== _section.sectionKey ? 'collapsed' : ''}` }
                            key={ `${_section.sectionKey}_${uuidv4()}`}
                          >
                            <a
                              className="menuSectionLabel collapsibleSectionHeader"
                              href={ `${window.location.pathname}?help_page=${_section.sectionKey}_section_index` }
                            >
                              <div className="headerLeft">
                                { _section.label }
                              </div>
                              <div className="headerRight">
                                <strong className="sectionCount">
                                  { getOrderedPagesForSection( _main.mainKey, _section.sectionKey )?.length - 1 }
                                </strong>
                                <span className="carretWrapper">
                                  <InlineSVG type="carretUp"/>
                                </span>
                              </div>
                            </a>
                            <div className="collapsibleSectionBody">
                              <ul>
                                {
                                  // eslint-disable-next-line max-len
                                  getOrderedPagesForSection( _main.mainKey, _section.sectionKey )?.map( _page => {
                                    if (
                                      !_page?.pageKey?.includes( '_section_index' )
                                      && !_page?.pageKey?.includes( '_main_index' )
                                    ) {
                                      return <li
                                        className={ `menuPage ${_page.pageKey}`}
                                        key={ `${_page.pageKey}_${uuidv4()}`}
                                      >
                                        <a
                                          className={ `menuPageLabel ${ pageKey === _page.pageKey ? 'isSelected' : ''}`}
                                          href={ `${window.location.pathname}?help_page=${_page.pageKey}` }
                                        >
                                          { _page.label }
                                        </a>
                                      </li>;
                                    }
                                  } )
                                }
                              </ul>
                            </div>
                          </li>;
                        } )
                      }
                    </ul>
                  </div>
                </li>;
              }
              return <React.Fragment key={uuidv4()}>
              </React.Fragment>;
            } )
          }
          {/* the change logs are dynamic, so need to do something a bit different for how they are shown */}
          <li
            // eslint-disable-next-line max-len
            className={ `menuMain product_information collapsibleSectionWrapper ${ mainKey !== 'product_information' ? 'collapsed' : ''}` }
            key={ 'product_information' }
          >
            <a
              className="menuMainLabel collapsibleSectionHeader"
              href={ `${window.location.pathname}?help_page=product_information` }
            >
              <div className="headerLeft">
                <h3>Product Information</h3>
              </div>
              <div className="headerRight">
                <strong className="sectionCount">3</strong>
                <span className="carretWrapper">
                  <InlineSVG type="carretUp"/>
                </span>
              </div>
            </a>
            <div className="collapsibleSectionBody">
              <ul>
                <li className="menuPage changelogs" >
                  <a
                    className={ `menuPageLabel ${ pageKey === 'changelogs' ? 'isSelected' : ''}`}
                    href={ `${window.location.pathname}?help_page=changelogs` }
                  >
                    Changelogs
                  </a>
                </li>
                <li className="menuPage licenses" >
                  <a
                    className={ `menuPageLabel ${ pageKey === 'licenses' ? 'isSelected' : ''}`}
                    href={ `${window.location.pathname}?help_page=licenses` }
                  >
                    Open Source Licenses
                  </a>
                </li>
                <li className="menuPage eula" >
                  <a
                    className={ `menuPageLabel ${ pageKey === 'eula' ? 'isSelected' : ''}`}
                    href={ `${window.location.pathname}?help_page=eula` }
                  >
                    End User License Agreement (EULA)
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
      <div className="helpDocumentationWrapper" id="helpDocumentationWrapper" >
        { loading && <Loading /> }
        {
          ( isNotEmpty( helpContent ) ) &&
          <React.Fragment>
            {
              ( isNotEmpty( pageKey ) && pageKey !== 'documentation_index' ) &&
              <div className="helpDocumentationHeader">
                <div className="breadcrumbsContainer">
                  <a
                    className="breadcrumbLink"
                    href={ `${window.location.pathname}?help_page=${mainKey}_main_index` }
                  >
                    { getLabelFor( mainKey, 'main' ) }
                  </a>
                  <span>/</span>
                  {
                    isNotEmpty( sectionKey ) &&
                    <React.Fragment>
                      <a
                        className="breadcrumbLink"
                        href={ `${window.location.pathname}?help_page=${sectionKey}_section_index` }
                      >
                        { getLabelFor( sectionKey, 'section' ) }
                      </a>
                      <span>/</span>
                    </React.Fragment>
                  }
                  <div className="breadcrumbLink current">
                    { getLabelFor( pageKey, 'page' ) }
                  </div>
                </div>
                <h1 className="pageHeader">{ getLabelFor( pageKey, 'page' ) }</h1>
              </div>
            }
            <div
              // eslint-disable-next-line max-len
              className={ `helpContentWrapper main--${mainKey} section--${sectionKey} page--${pageKey}` }
              ref={helpContentContainer}
            >
              {
                isNotEmpty( helpContent ) &&
                <React.Fragment>
                  {
                    ( isNotEmpty( pageKey ) && productInformationPages.includes( pageKey ) )
                      ? <ProductInformationPageContent content={helpContent} pageKey={pageKey} md={md} />
                      : <div
                        className="markdownContent pageContent"
                        dangerouslySetInnerHTML={
                          { __html: md.render( helpContent.content ) }
                        }
                      />
                  }
                </React.Fragment>
              }
            </div>
            <div className="helpDocumentationFooter">
              {
                isNotEmpty( previousPage )
                  ? <a
                    href={ `${window.location.pathname}?help_page=${previousPage.pageKey}` }
                    className="helpPaginationButton previous"
                  >
                    <InlineSVG type="carretLeft" />
                    {
                      isNotEmpty( previousPage.sectionKey )
                        ? <span>{ getLabelFor( previousPage.sectionKey, 'section' ) }: </span>
                        : <React.Fragment>
                          {
                            isNotEmpty( previousPage.mainKey )
                              ? <span>{ getLabelFor( previousPage.mainKey, 'main' ) }: </span>
                              : <span>Documentation: </span>
                          }
                        </React.Fragment>
                    }
                    <strong>{ getLabelFor( previousPage.pageKey, 'page' ) }</strong>
                  </a>
                  : <button className="helpPaginationButton next disabled">
                    <InlineSVG type="carretLeft" />
                    <strong>First Page</strong>
                  </button>
              }
              {
                isNotEmpty( nextPage )
                  ? <a
                    href={ `${window.location.pathname}?help_page=${nextPage.pageKey}` }
                    className="helpPaginationButton next"
                  >
                    {
                      isNotEmpty( nextPage.sectionKey )
                        ? <span>{ getLabelFor( nextPage.sectionKey, 'section' ) }: </span>
                        : <span>{ getLabelFor( nextPage.mainKey, 'main' ) }: </span>
                    }
                    <strong>{ getLabelFor( nextPage.pageKey, 'page' ) }</strong>
                    <InlineSVG type="carretRight" />
                  </a>
                  : <button disabled className="helpPaginationButton next disabled">
                    <strong>Last Page</strong>
                    <InlineSVG type="carretRight" />
                  </button>
              }
            </div>
          </React.Fragment>
        }
      </div>
    </div>
  );
};

export default Content;
